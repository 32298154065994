import { render, staticRenderFns } from "./profile-form-page.vue?vue&type=template&id=38631037&scoped=true&"
import script from "./profile-form-page.vue?vue&type=script&lang=js&"
export * from "./profile-form-page.vue?vue&type=script&lang=js&"
import style0 from "./profile-form-page.vue?vue&type=style&index=0&id=38631037&lang=scss&scoped=true&"
import style1 from "./profile-form-page.vue?vue&type=style&index=1&id=38631037&lang=scss&scoped=true&"
import style2 from "./profile-form-page.vue?vue&type=style&index=2&id=38631037&scoped=true&lang=css&"
import style3 from "./profile-form-page.vue?vue&type=style&index=3&id=38631037&lang=scss&scoped=true&"
import style4 from "./profile-form-page.vue?vue&type=style&index=4&id=38631037&lang=scss&scoped=true&"
import style5 from "./profile-form-page.vue?vue&type=style&index=5&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38631037",
  null
  
)

export default component.exports
import {QBreadcrumbs,QIcon,QBreadcrumbsEl,QSeparator,QForm,QInput,QBadge,QBtn,QSpinnerIos,QField} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBreadcrumbs,QIcon,QBreadcrumbsEl,QSeparator,QForm,QInput,QBadge,QBtn,QSpinnerIos,QField})
