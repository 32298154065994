//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { UserModel } from '@/modules/auth/user-model';
import { mapGetters, mapActions } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import i18n  from '@/vueI18n';
import moment from 'moment'
const { fields } = UserModel;

const formSchema = new FormSchema([
  fields.firstName,
  fields.lastName,
  fields.phoneNumber,
  fields.avatarsProfile,
  fields.fullName,
  fields.email,
  fields.disabledAsStatus,
  fields.roles,
  fields.createdAt,
  fields.updatedAt
]);

export default {
  name: 'app-profile-form-page',
  props:['record','iam'],

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
    };
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      saveLoading: 'auth/loadingUpdateProfile',
      currentUser: 'auth/currentUser',
      is_screen_xs: 'layout/is_screen_xs',
    }),

    fields() {
      return fields;
    },
    language(){
      return i18n.locale
    }
  },
  watch:{
    record(newVal){
      if(newVal){
        this.model = formSchema.initialValues(newVal);
        this.model.authenticationUid = newVal.authenticationUid
        this.model.createdAt = this.getDate(this.model.createdAt)
        this.model.updatedAt = this.getDate(this.model.updatedAt)
      }
    },
     language() {
               document.title =
            this.i18n('app.title') +
            ' | ' +
            this.i18n('auth.profile.title') 
    },
  },
  async created() {
    if(!this.iam){
      this.model = formSchema.initialValues(this.currentUser);
      this.model.authenticationUid = this.currentUser.authenticationUid
    }
    console.log(this.fields);
  },

  methods: {
    ...mapActions({
      doUpdateProfile: 'auth/doUpdateProfile',
      doDisableAllSelected: 'iam/list/doDisableAllSelectedById',
      doEnableAllSelected: 'iam/list/doEnableAllSelectedById',
    }),
    getDate(date){
      return moment(new Date(date)).locale('en').format('DD/MM/YYYY')
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
    doReset() {
      this.model = formSchema.initialValues(
        this.currentUser,
      );
    },

    async doSubmit() {
      // try {
      //   await this.$refs.form.validate();
      // } catch (error) {
      //   return;
      // }

      const values = formSchema.cast(this.model);
      delete values.fullName
      delete values.email
      this.doUpdateProfile(values);
    },
  },
};
